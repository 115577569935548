import gql from 'graphql-tag';

import { USER_TYPE } from '../../user/graphql/UserType.gql';

const EMPLOYEES_TYPE = gql`
  fragment EmployeesType on EmployeeType {
    id
    user {
      id
      username
    }
  }
`;

export const EMPLOYEE_TYPE = gql`
  ${USER_TYPE}, ${EMPLOYEES_TYPE}
  fragment EmployeeType on EmployeeType {
    id
    organizationSet {
      edges {
        node {
          id
          name
          email
          poc {
            id
            user {
              id
            }
          }
          ceo {
            id
            user {
              id
            }
          }
          allowComparison
        }
      }
    }
    verticalMembersEmployee {
      edges {
        node {
          id
          heads {
            edges {
              node {
                id
                user {
                  id
                }
              }
            }
          }
        }
      }
    }
    teamManagerEmployee {
      totalCount
      edges {
        node {
          id
          name
          managers {
            edges {
              node {
                id
                user {
                  id
                }
              }
            }
          }
          employees {
            edges {
              node {
                ...EmployeesType
              }
            }
          }
        }
      }
    }
    teamMembersEmployee {
      edges {
        node {
          id
          managers {
            edges {
              node {
                id
                user {
                  id
                }
              }
            }
          }
          employees {
            edges {
              node {
                ...EmployeesType
              }
            }
          }
        }
      }
    }
    orgPocEmployee{
      totalCount
    }
    orgCeoEmployee{
      totalCount
    }
    verticalHeadEmployee{
      totalCount
    }
    user {
      ...UserType
    }
    createdBy {
      ...UserType
    }
    updatedBy {
      ...UserType
    }
  }
`;
