import { Row, Col, Button, Dropdown, Menu, Table, Spin, Card, Divider, Select } from 'antd';
import { LeftOutlined, DownOutlined } from '@ant-design/icons';
import ResponseSetTable from "modules/survey/components/Surver360ResponseSetTable";
import TeamMember360ReportView from "modules/survey/components/TeamMember360SurveyReport";
import React from "react";
import styled from "styled-components";
import { organization_icons } from 'modules/poc-dashboard/icons';
import OrgTeamMember360ReportView from './OrgTeamMemberRerportView';


const TeamAssessmentReportView = (props) => {
  const { me, history, SurveyReport, surveyDetail, employees, changeUser, responseSet, get360Report, navigateRoute } = props
  console.log("SurveyReport",SurveyReport)
  const [loading, setloading] = React.useState(false);
  const [ReportList, setReportList] = React.useState([]);
  const [selectedEmployee, SetSelectedEmployee] = React.useState();
  const [userResponseSet, setuserResponseSet] = React.useState([]);
  const [Report360, set360Report] = React.useState();
  const [SurveyReportDetail, setSurveyReportDetail] = React.useState();

  React.useEffect(() => {
    if (SurveyReport) {
      setSurveyReportDetail(SurveyReport)
      setloading(false)
    }
  }, [SurveyReport])

  React.useEffect(() => {
    if (Report360) {
      setloading(true)
      get360Report({ user: selectedEmployee?.id, id: Report360?.id })
    }
  }, [Report360])

  React.useEffect(() => {
    if (surveyDetail) {
      setloading(false)
    }
  }, [surveyDetail])

  React.useEffect(() => {
    if (responseSet) {
      setSurveyReportDetail(null)
      let user = responseSet?.employee?.organizationSet?.edges[0]?.node?.employees?.edges?.find(item => item?.node?.user?.id === selectedEmployee?.id)
      if (user) {
        let list = user?.node?.user?.responseSet?.edges?.map(({ node }) => node)
        setuserResponseSet(list)
      }
      else {
        setuserResponseSet([])
      }
      setloading(false)
    }
  }, [responseSet])

  React.useEffect(() => {
    if (selectedEmployee) {
      setloading(true)
      changeUser({ user: selectedEmployee?.id })
    }
  }, [selectedEmployee])

  const back = () => {
    //   window.history.back();
    history.goBack()
  };

  const handleMenuClick = (data) => {
    SetSelectedEmployee(data)
  }

  return (
    <>
      <Spin spinning={loading}>
        <div style={{ padding: '2em 20px' }}>
          {
            surveyDetail && (
              <>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "start", gap: "1em", alignItems: "center" }}>
                  <img src={organization_icons?.back_arrow} alt="back_arrow" style={{ width: "25px", cursor: "pointer" }} onClick={() => navigateRoute("-1")} />
                  <h4 className="poc-dashboard-card-title" style={{ fontWeight: "600",whiteSpace:"wrap"}}>{selectedEmployee && (<span>{selectedEmployee?.firstName}'s</span>)} 360 Report for {surveyDetail?.name}</h4>
                </div>
                <div style={{ marginTop: "1.5em" }}>
                  <Col>
                    <Select
                      className='org-poc-select-filed'
                      showSearch={true}
                      style={{ width: "100%", marginTop: "1em", maxWidth: "20em" }}
                      placeholder="Select Employee"
                      onChange={(e,option)=> handleMenuClick({id:option?.key,firstName:option?.value})}
                    >
                      {
                        employees?.length ?
                          employees?.map((data) =>
                            <Menu.Item className="org-option" key={data?.id} value={data?.firstName}>
                              {data?.firstName}
                            </Menu.Item>
                          )
                          :
                          <Menu.Item >
                            no user found
                          </Menu.Item>
                      }
                    </Select>
                  </Col>


                  {
                    selectedEmployee && (
                      <ColScroll style={{ height: '250px', overflow: 'auto', margin: '15px 0px', padding: '0px 15px' }}>
                        <ResponseSetTable
                          style={{backgroundColor: 'transparent'}}
                          dataSource={userResponseSet}
                          setChart={(e) => { set360Report(e) }}
                          selectedDetail={
                            {
                              name: selectedEmployee?.firstName,
                              survey: surveyDetail?.name
                            }
                          }
                        />
                      </ColScroll>

                    )
                  }


                  {
                    SurveyReportDetail && (
                      <>
                        <Divider style={{ borderColor: "#4CCB1F"}} />
                        <OrgTeamMember360ReportView
                          SurveyReport={SurveyReportDetail}
                          downloadComplete={() => { setSurveyReportDetail(null); set360Report(null) }}
                          loading={(e) => { setloading(e) }}
                        />
                      </>
                    )
                  }

                </div>
              </>
            )
          }
        </div>
      </Spin>
    </>
  )

}

export default TeamAssessmentReportView

export const MyTable = styled(Table)`
  table {
    border-collapse: collapse !important;
    background-color: rgba(213, 229, 242, 0.2) !important;
    background: rgba(213, 229, 242, 0.2) !important;
    th{
        text-align:center !important;
         border: 1px solid rgba(170, 186, 198, 1)!important;
        font-weight:600;
        font-family:Poppins,
        font-size:1em;
        padding-bottom:5px !important;
        background:#fff !important;
    }
    tbody {
            border: 1px solid rgba(170, 186, 198, 1)!important;
            background: rgba(213, 229, 242, 0.2);
        tr{
        border: 1px solid rgba(170, 186, 198, 1)!important;
        border-style: solid none !important;
        }
      td{
        font-size:1em;
        text-align:center !important;
        font-weight:400;
        font-family:Poppins;
        border: 1px solid rgba(170, 186, 198, 1)!important;
      } 
    p{
    margin:0px !important;
    }
    .ant-table-cell {
    padding: 5px 10px !important;

  }
`;

const DropdownButton = styled(Button)`
width:230px;
display:flex;
justify-content:space-between;
align-items:center;
gap:1em
border-radius:.5em
`

const ColScroll = styled(Col)`
::-webkit-scrollbar {
  height: 0px;
  width: 4px;
  display: block;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #e0e0e0;
  border-radius: 10px;
  padding-right: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color:#B0CB1F;
}
`