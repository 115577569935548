import { Button, Input, message, Select, Spin, Tooltip } from "antd";
import { bulk_invitation_limit } from "../../../../config";
import { validateEmail } from "modules/look";
import { dashboard_home_icons, organization_icons } from "modules/poc-dashboard/icons";
import React from "react";


const CreateInvitationUserView = (props) => {
    const { navigateRoute, verticals, getTeamList, teams, app_sidebar_expand, getverticalList, SetRegisterData, formData, setFormData, loadingapi,me,organization_detail} = props

    let form_key = React.useRef(1)


    const user_type = [
        {
            value: "vertical_head",
            label: "Vertical Head"
        },
        {
            value: "manager",
            label: "Manager"
        },
        {
            value: "employee",
            label: "Employee"
        }
    ]

    const addForm = () => {
        if (formData?.length >= bulk_invitation_limit) {
            message.destroy()
            message.error(`Cannot invite more than ${bulk_invitation_limit} employees at a time.`)
        }
        form_key.current = form_key?.current + 1
        let form_data = [...formData];
        form_data.push({ key: form_key?.current, vertical: null, team: null, email: null, firstName: null, lastName: null, user_type: null, error: false, error_message: null, success: false })
        setFormData(form_data);
        setTimeout(() => {
            let doc = document.getElementById("auto-scroll-view")
            if(doc){
                doc.scrollIntoView({inline:"end",behavior:"smooth"})
            } 
        }, 250);
    };
    const handleChange = (key, field, value) => {
        const updatedFormData = [...formData];
        let node = updatedFormData?.find(i => i?.key == key)
        if (node) {
            node[field] = value?.trim();
            if (field == "vertical") {
                node.team = null
                getTeamList({ key, vertical: value })
            }
            setFormData(updatedFormData);
        }
    };

    const handleInvitation = () => {
        console.log('meme',me);
        
        if (!formData?.length) {
            message.error("Please add data")
            return
        }
        let list = formData?.map(i => {
            let validation = checkValidation(i)
            return {
                ...i,
                error: !validation?.valid,
                error_message: validation?.error_message
            }
        })
        let invalid_data = list?.find(i => i.error)
        setFormData(list)
        if (!invalid_data) {
            SetRegisterData(list)
        }
    }

    const checkValidation = (value) => {
        if (!value.vertical) {
            return {
                valid: false,
                error_message: "Please select a vertical"
            }
        }
        else if (!value.firstName || !value.lastName) {
            return {
                valid: false,
                error_message: "Please provide name"
            }
        }
        else if (!value.email || !validateEmail(value?.email)) {
            return {
                valid: false,
                error_message: "Please provide valid email"
            }
        }
        else if(!validateEmailDomain(value?.email)){
            return {
                valid: false,
                error_message: "Users email domain didn't match with organization domain"
            }
        }
        else if (!value?.user_type) {
            return {
                valid: false,
                error_message: "Please select user type"
            }
        }
        else if (value.user_type == "manager" && !value?.team) {
            return {
                valid: false,
                error_message: "Please select a team"
            }
        }
        else {
            return {
                valid: true
            }
        }
    }

    const validateEmailDomain=(email)=>{
        if(organization_detail){
            let list = organization_detail?.email?.split('@')
            
            if(list?.length){
                let organization_email_domain = list[1]
                if(organization_email_domain){
                    return email?.includes(`@${organization_email_domain}`)
                }else{
                    return false
                }
            }else{
                return false
            }
        }else{
            return false
        }
        
    }
    const removeCell = (data) => {
        let list = [].concat(formData)
        let cell = list.find(i => i?.key == data?.key)
        if (cell) {
            list = list?.filter(i => i?.key != data?.key)
            setFormData(list)
        }
    }

    return (
        <Spin spinning={loadingapi} size="large">
            <div className="poc-dahboard-root">
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "start", gap: "1em", alignItems: "center" }}>
                    <img src={organization_icons?.back_arrow} alt="back_arrow" style={{ width: "25px", cursor: "pointer" }} onClick={() => navigateRoute("-1")} />
                    <h4 className="poc-dashboard-card-title">Invite Employees</h4>
                </div>
                <div className="poc-org-root create-employee-invitation-root" style={{ marginTop: "1em", width: "100%", padding: '1em' }}>

                    <div className="poc-height-limit" id="poc-height-limit">
                        <div className="poc-employee-invite-container invitation-overflow">
                            <table className="poc-employee-invite-table">
                                <thead>
                                    <tr>
                                        <th className='invite-form-label' style={{ textAlign: 'left', whiteSpace: "nowrap" }}>Vertical<span style={{ color: "#E86161" }}>*</span></th>

                                        <th className='invite-form-label' style={{ textAlign: 'left', whiteSpace: "nowrap" }}>Team</th>

                                        <th className='invite-form-label' style={{ textAlign: 'left', whiteSpace: "nowrap" }}>Email<span style={{ color: "#E86161" }}>*</span></th>

                                        <th className='invite-form-label' style={{ textAlign: 'left', whiteSpace: "nowrap" }}>First Name<span style={{ color: "#E86161" }}>*</span></th>

                                        <th className='invite-form-label' style={{ textAlign: 'left', whiteSpace: "nowrap" }}>Last Name<span style={{ color: "#E86161" }}>*</span></th>

                                        <th className='invite-form-label' style={{ textAlign: 'left', whiteSpace: "nowrap" }}>User Type<span style={{ color: "#E86161" }}>*</span></th>


                                    </tr>
                                </thead>
                                <tbody>
                                    {formData?.map((field, index) => (
                                        <Tooltip title={(field?.error && field.error_message)?field.error_message:""}>
                                            <tr key={field?.key} className={`poc-invite-table-body ${field?.error ? "create-invite-form-invalid" : ""} `}>

                                                <td className="invite-table-border-left">
                                                    <Select
                                                        className='org-poc-select-filed invite-form-input'
                                                        showSearch={true}
                                                        style={{ width: "100%", borderColor: "rgba(213, 229, 242, 1)" }}
                                                        placeholder="Select Vertical"
                                                        onChange={(e) =>
                                                            handleChange(field?.key, "vertical", e)
                                                        }
                                                        filterOption={false}
                                                        onSearch={(e) => getverticalList({ search: e })}
                                                        value={field.vertical}
                                                    >
                                                        {
                                                            verticals?.map(item => {
                                                                return <Select.Option className="org-option" key={item?.id} value={item?.id} >{item?.name}</Select.Option>
                                                            })
                                                        }
                                                    </Select>
                                                </td>
                                                <td>
                                                    <Select
                                                        className='org-poc-select-filed invite-form-input'
                                                        showSearch={true}
                                                        style={{ width: "100%", borderColor: "rgba(213, 229, 242, 1)" }}
                                                        placeholder="Select Team"
                                                        onChange={(e) =>
                                                            handleChange(field?.key, "team", e)
                                                        }
                                                        filterOption={false}
                                                        value={field?.team}
                                                        disabled={!field?.vertical}
                                                        onSearch={(e) => getTeamList({ key: field?.key, vertical: field?.vertical, search: e })}
                                                    >
                                                        {
                                                            teams[`team_${field?.key}_${field?.vertical}`]?.map(item => {
                                                                return <Select.Option className="org-option" key={item?.id} value={item?.id} >{item?.name}</Select.Option>
                                                            })
                                                        }
                                                    </Select>
                                                </td>
                                                <td>
                                                    <Input
                                                        className='vertical-input invite-form-input'
                                                        name="email" maxLength={200}
                                                        style={{ height: "2.5em", borderColor: "rgba(213, 229, 242, 1)", fontSize: '1em' }}
                                                        placeholder='Enter Email Id'
                                                        value={field.email}
                                                        onChange={(e) =>
                                                            handleChange(field?.key, "email", e.target.value)
                                                        }
                                                        autoComplete="off"
                                                    ></Input>
                                                </td>
                                                <td>
                                                    <Input
                                                        className='vertical-input invite-form-input'
                                                        name="email"
                                                        maxLength={200}
                                                        style={{ height: "2.5em", borderColor: "rgba(213, 229, 242, 1)", fontSize: '1em' }}
                                                        placeholder='Enter Firstname'
                                                        value={field.firtName}
                                                        onChange={(e) =>
                                                            handleChange(field?.key, "firstName", e.target.value)
                                                        }
                                                        autoComplete="off"
                                                    ></Input>
                                                </td>
                                                <td>
                                                    <Input
                                                        className='vertical-input invite-form-input'
                                                        name="email"
                                                        maxLength={200}
                                                        style={{ height: "2.5em", borderColor: "rgba(213, 229, 242, 1)", fontSize: '1em' }}
                                                        placeholder='Enter Lastname'
                                                        value={field.lastName}
                                                        onChange={(e) =>
                                                            handleChange(field?.key, "lastName", e.target.value)
                                                        }
                                                        autoComplete="off"
                                                    ></Input>
                                                </td>
                                                <td className="invite-table-border-right">
                                                    <div className="action-row-container">
                                                        <Select
                                                            className='org-poc-select-filed invite-form-input'
                                                            showSearch={true}
                                                            style={{ width: "100%", borderColor: "rgba(213, 229, 242, 1)" }}
                                                            placeholder="Select User Type"
                                                            onChange={(e) =>
                                                                handleChange(field?.key, "user_type", e)
                                                            }
                                                        >
                                                            {
                                                                user_type?.map(item => {
                                                                    return <Select.Option className="org-option" key={item?.value} value={item?.value} >{item?.label}</Select.Option>
                                                                })
                                                            }
                                                        </Select>
                                                        <img src={dashboard_home_icons?.close_icons} alt="" onClick={() => removeCell(field)} style={{ cursor: "pointer" }} />
                                                    </div>
                                                </td>

                                            </tr>
                                        </Tooltip>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div id="auto-scroll-view" style={{width:"1px",height:"1px"}}></div>
                    </div>
                    <Button className="add-mode-btn" onClick={() => addForm()} disabled={formData?.length >= bulk_invitation_limit}>Add More</Button>
                    {
                        formData?.length >= bulk_invitation_limit ?
                            <p style={{ color: "#E86161", marginTop: "0.25em" }}>* You can only invite {bulk_invitation_limit} employees at once.</p> : null
                    }

                </div>
                <div className={`one-on-one-bottom-div ${app_sidebar_expand ? 'one-on-one-div-margin-left' : ''}`}>
                    <Button className="org-invite-cancel-btn" style={{ width: "7em" }} onClick={() => navigateRoute("-1")}>Cancel</Button>
                    <Button className="poc-active-btn" style={{ width: "7em" }} onClick={() => handleInvitation()} disabled={!formData?.length}>Invite</Button>
                </div>
            </div>
        </Spin>
    )
}

export default CreateInvitationUserView;