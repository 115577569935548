import React from "react"
import { compose } from "modules/core"
import { withApollo } from '@apollo/client/react/hoc'
import ReportView from "../component/report"
import { getIntFromString, globalPermissionValidator } from "modules/look"
import { survey_permission } from "Permissions/survey.permission"
import { GET_USER_RESPONSE_LIST } from "modules/survey-module/graphql/survey.gql"
import { report_tool_data, SURVEY_DIVERSITY_PROFILE, SURVEY_ID_3CS, SURVEY_WHOLE_IDENTITY, SURVEY_WHOPOSHORO } from "../../../../config"

const ReportContainer = (props) => {
    const { userPermission, client, me } = props
    const [permission, setPermission] = React.useState()
    const [response_loading, setResponseLoading] = React.useState(true)
    const [all_response, SetAllResponse] = React.useState([])
    const [page_info,SetPageInfo] = React.useState()
    
    const response_ref = React.useRef([])

    React.useEffect(()=>{
        response_ref.current = all_response
    },[all_response])

    //Permission Check
    React.useEffect(() => {
        if (userPermission?.length) {
            let permission = globalPermissionValidator(survey_permission, userPermission)
            setPermission(permission)
        }
    }, [userPermission])

    React.useEffect(() => {
        if (permission && permission?.required_permission && me) {
            getResponseList()
        }
    }, [permission, me])

    const getResponseList = async (filter) => {
        try {
            let survey_In = [SURVEY_WHOPOSHORO,SURVEY_DIVERSITY_PROFILE,SURVEY_WHOLE_IDENTITY,SURVEY_ID_3CS]
            setResponseLoading(true)
            const { data } = await client.query({
                query: GET_USER_RESPONSE_LIST,
                variables: { ...filter,user: me?.id, first: 10,survey_In },
                fetchPolicy: 'network-only'
            });
            if(data?.surveyResponses){
                let list = data?.surveyResponses?.edges?.map(({node})=>node)
                list = list?.map(i=>{
                    let report_tool = report_tool_data?.find(t=>t.survey_id==getIntFromString(i?.survey?.id))
                    return {
                        ...i,
                        report_tool_id:report_tool?.tool_id,
                    }
                })
                if(filter?.after){
                    list = response_ref.current?.concat(list)
                }
                SetPageInfo(data?.surveyResponses?.pageInfo)
                SetAllResponse(list)
                setResponseLoading(false)
            }
        } catch (error) {
        }
    }

    return (
        <ReportView {...props} response_loading={response_loading} page_info={page_info} all_response={all_response} getResponseList={getResponseList} />
    )
}

export default compose(withApollo)(ReportContainer)