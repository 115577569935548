
import React from "react";
import { dashboard_home_icons } from "../../icons";
import { Button, Progress, Spin } from "antd";
import PocDashboardAssessmentContainer from "../../containers/dashboard/pocDasboardSurveyAssessmentContainer";
import poc_dashboard_routes from "modules/poc-dashboard/routes";
const PocDashboardHomeView = (props) => {

    const { view_dashboard_cards, org_survey_list, org_employee_level_count, banner_card_loading, navigateRoute, licenses, licenses_loading } = props
    const [banner_cards, setBannerCards] = React.useState([
        {
            icon: dashboard_home_icons.total_vertical_icon,
            title: "Total Verticals",
            key: "total_verticals",
            visibility: view_dashboard_cards.total_verticals
        },
        {
            icon: dashboard_home_icons.total_team_icon,
            title: "Total Team",
            key: "total_teams",
            visibility: view_dashboard_cards.total_teams
        },
        {
            icon: dashboard_home_icons.total_managers_icon,
            title: "Total Managers",
            key: "total_mangers",
            visibility: view_dashboard_cards.total_managers
        },
        {
            icon: dashboard_home_icons.total_reportee_icon,
            title: "Total Reportees",
            key: "total_reporties",
            visibility: view_dashboard_cards.total_reporties
        }
    ])
    React.useEffect(() => {
        if (org_employee_level_count) {
            let count_card = banner_cards.map(item => {
                return {
                    ...item,
                    count: org_employee_level_count[item?.key]
                }
            })
            setBannerCards(count_card)
        }
    }, [org_employee_level_count])

    const licenceProgressCount = (progress) => {
        return (<div>
            <h4 className="poc-licence-sub-title" style={{ fontWeight: "600", margin: "0px", fontSize: "1em" }}>{progress}%</h4>
            <p className="poc-licence-sub-title" style={{ color: "#AABAC6", margin: "0px", fontSize: ".5em" }}>Used</p>
        </div>)
    }
    return (
        <div className="poc-dahboard-root">
            <Spin spinning={banner_card_loading}>
                <div className="dashboard-home-banner-cards">
                    {banner_cards?.map(item => (
                        <div className="dashboard-banner-card-continer">
                            <div className="dasboard-banner-card">
                                <img className="poc-banner-icon" src={item?.icon} alt="" />
                                <h4 className="poc-banner-title" style={{ marginTop: ".5em" }}>{item?.title}</h4>
                                <h4 className="poc-banner-count">{item?.count}</h4>
                            </div>
                        </div>
                    ))}
                </div>
            </Spin>
            <div className="poc-card-flex">
                <div style={{ width: "100%", height: "100%" }}>
                    {view_dashboard_cards.analysis ? <div className="dasboard-banner-card" style={{ height: "28em" }}>
                        <h4 className="poc-dashboard-card-title">Analytics</h4>
                        <div style={{ height: "20em", display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                            <h4 style={{ fontSize: "1.5em" }}>Upcoming Feature</h4>
                        </div>
                    </div>
                        : view_dashboard_cards.invite_employees &&
                        <Spin spinning={banner_card_loading}>
                            <div className="dasboard-banner-card">
                                <h4 className="poc-dashboard-card-title">Invite Employees</h4>
                                <div className="poc-align-spacebetween">

                                    <div style={{ position: "relative" }}>
                                        <img style={{ height: "3em", width: "3em", borderRadius: "50%", position: "absolute", left: `1em`, top: "-18px", cursor: "pointer" }} src={dashboard_home_icons.add_user} alt="add_user" />
                                    </div>
                                    <div>
                                        {!banner_card_loading && <Button className="poc-active-btn" disabled={org_employee_level_count?.total_verticals == 0} onClick={() => navigateRoute(poc_dashboard_routes?.create_invitation)}>INVITE EMPLOYEE</Button>}
                                    </div>
                                </div>
                            </div>
                        </Spin>
                    }
                </div>
                <div style={{ width: "100%", height: "100%" }}>
                    {view_dashboard_cards.licence && <div className="dasboard-banner-card">
                        <h4 className="poc-dashboard-card-title">License</h4>
                            {(licenses && !licenses_loading) && (
                                <div className="poc-align-spacebetween">
                                    <div>
                                        <h4 className="poc-licence-sub-title">Total License - <span style={{ fontWeight: "600" }}>{licenses?.total_license}</span></h4>
                                        <h4 className="poc-licence-sub-title" style={{ marginTop: ".5em" }}>Used License - <span style={{ fontWeight: "600" }}>{licenses?.usage}</span></h4>
                                    </div>
                                    <div>
                                        <Progress type="circle" percent={licenses?.percentage} format={() => licenceProgressCount(licenses?.percentage)} strokeColor={{ from: '#4CCB1F', to: '#4CCB1F' }} strokeWidth={10} />
                                    </div>
                                    {/* <Button className="poc-active-btn">REQUEST RENEWAL </Button> */}
                                </div>
                            )}
                            {(!licenses && !licenses_loading) && (
                                <h4 style={{fontSize:"1.25em",marginTop:"1em"}}>No License Detail Found</h4>
                            )}
                    </div>}
                    {view_dashboard_cards.analysis && view_dashboard_cards.invite_employees &&
                        <Spin spinning={banner_card_loading}>
                            <div className="dasboard-banner-card" style={{ marginTop: "2em" }}>
                                <h4 className="poc-dashboard-card-title">Invite Employees</h4>

                                <div className="poc-align-spacebetween" style={{ marginTop: "1em", paddingBottom: "1em" }}>
                                    <div style={{ position: "relative" }}>
                                        <img style={{ height: "3em", width: "3em", borderRadius: "50%", position: "absolute", left: `1em`, top: "-18px", cursor: "pointer" }} src={dashboard_home_icons.add_user} alt="add_user" />
                                    </div>
                                    <div>
                                        {!banner_card_loading && <Button className="poc-active-btn" onClick={() => navigateRoute(poc_dashboard_routes?.create_invitation)} disabled={org_employee_level_count?.total_verticals == 0}>INVITE EMPLOYEE</Button>}
                                    </div>
                                </div>
                            </div>
                        </Spin>}
                </div>
            </div>
            <div style={{ marginTop: "2em" }}>
                <PocDashboardAssessmentContainer {...props} />
            </div>
        </div>
    )
}

export default PocDashboardHomeView;