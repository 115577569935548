import CreateInvitationContainer from 'modules/survey-module/survey/container/CreateInvitationContainer';
import PocDasboardHomeContainer from './containers/dashboard/PocDashboardHomeContainer';
import SurveyResponseVertical from './containers/dashboard/surveyResponseVerticalContainer';
import PocOrganizationHomeContainer from './containers/organization/organizationHomeContainer';
import ROUTE from './routes';
import CreateInviteUserContainer from './containers/organization/createInvitationContiner';
import assessmentReportContainer from './containers/dashboard/assessmentReportContainer';
import TeamAssessmentContainer from './containers/dashboard/teamAssessmentReportContainer';


export default [
        {
                name: 'PocDashboardHome',
                path: ROUTE.dashboard_home,
                component: PocDasboardHomeContainer,
                exact: true,
                protect: true
        },
        {
                name:"Poc Organization Home",
                path:ROUTE.poc_organization,
                component:PocOrganizationHomeContainer,
                exact: true,
                protect: true
        },
        {
                name:"Poc Vertical Detail",
                path:ROUTE.poc_vertical_detail,
                component: SurveyResponseVertical,
                exact: true,
                protect: true
        },
        {
                name:"Poc Create Invitation",
                path:ROUTE.create_invitation,
                component: CreateInviteUserContainer,
                exact: true,
                protect: true
        },
        {
                name:"Poc assessment report",
                path:ROUTE.assessment_report,
                component:assessmentReportContainer,
                exact: true,
                protect: true
        },
        {
                name:"Poc team assessment report",
                path:ROUTE.team_assessment_report,
                component: TeamAssessmentContainer,
                exact: true,
                protect: true
        }
]