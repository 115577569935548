import React from 'react';
import '../../css/poc-dashboard-style.css';
import PocDashboardHomeView from '../../components/dashboard/PocDashboardHomeView';
import { compose } from 'modules/core';
import { withApollo } from '@apollo/client/react/hoc';
import { LISCENCING_DETAIL, ORG_EMPLOYEE_LEVEL_COUNTS, ORG_POC_SURVEY_LIST } from 'modules/poc-dashboard/graphql/pocDashboardQuery.gql';
import { getIntFromString, globalPermissionValidator } from 'modules/look';
import { poc_admin_permission } from 'Permissions/poc_admin.permission';
import NoPermissionView from 'modules/kudos-user/component/NoPermissionComponent';
import { Spin } from 'antd';
const PocDasboardHomeContainer = (props) => {
    const { me, client, userPermission } = props
    const [org_survey_list, setOrgSurveyList] = React.useState()
    const [org_employee_level_count, setOrgEmployeeLevelCount] = React.useState()
    const [banner_card_loading, setBannerCardsLoading] = React.useState(false)
    const [permission, setPermission] = React.useState()
    const [view_dashboard_cards, setViewDashboardCards] = React.useState({
        total_verticals: true,
        total_teams: true,
        total_managers: true,
        total_reporties: true,
        analysis: true,
        licence: true,
        invite_employees: true,
        survey_assessment_status: true,
    })
    const [licenses,Setlicenses] = React.useState()
    const [licenses_loading,SetlicensesLoading] = React.useState(false)
   
    //Permission Check
    React.useEffect(() => {
        if (userPermission?.length && me) {
            let permission = globalPermissionValidator(poc_admin_permission, userPermission)
            
            if (permission.required_permission && !me?.employee?.orgPocEmployee?.totalCount) {
                permission.required_permission = false
            }
            if (permission.required_permission && !me?.employee?.organizationSet?.edges?.length) {
                permission.required_permission = false
            }
            
            setPermission(permission)
        }
    }, [userPermission, me])

    React.useEffect(() => {
        if (permission?.required_permission) {
            getOrgSurveyList()
            getOrgEmployeeLevelCount()
            let org_id = me?.employee?.organizationSet?.edges[0]?.node?.id
            if(org_id){
                getLiscensingDetail(org_id)
            }
            
        }
    }, [permission])

    const getLiscensingDetail = async(id) =>{
       try {
        SetlicensesLoading(true)
        const { data } = await client.query({
            query: LISCENCING_DETAIL,
            variables:{organization:getIntFromString(id),id},
            fetchPolicy: 'network-only'
        });
        if (data) {
            let license_detail = data?.licenses?.edges[0]?.node
            if(license_detail){
                let usage=data?.organization?.employees?.totalCount
                let total_license = license_detail?.plan?.licenses
                let percentage = Math.round((usage/total_license)*100)
                let detail = {
                    usage,
                    license_detail,
                    total_license,
                    percentage
                }
                Setlicenses(detail)
            }
        
            SetlicensesLoading(false)
        }else{
            SetlicensesLoading(false)
        }
       } catch (error) {
        SetlicensesLoading(false)
       }
    }
    const getOrgSurveyList = async () => {
        const { data } = await client.query({
            query: ORG_POC_SURVEY_LIST,
            fetchPolicy: 'network-only'
        });
        if (data?.allOrgSurveysByImplicitOrgId?.edges?.length) {
            setOrgSurveyList(data?.allOrgSurveysByImplicitOrgId?.edges?.map(({ node }) => node))
        }
    }
    const managerCount = (data) => {
        if (data) {
            const headsIds = data?.verticalSet?.edges
                ?.flatMap(({ node }) => node?.heads?.edges?.map(item => item?.node?.user?.id))
                ?.filter(Boolean);

            const managersIds = data?.verticalSet?.edges
                ?.flatMap(({ node }) => node?.teamSet?.edges?.flatMap(item => item?.node?.managers?.edges?.map(value => value?.node?.user?.id)))
                ?.filter(Boolean);

            const allIds = [...headsIds, ...managersIds];
            if (data?.ceo?.user?.id) {
                allIds.push(data?.ceo?.user?.id)
            }
            if (data?.pco?.user?.id && (data?.poc?.verticalHeadEmployee?.totalCount || data?.poc?.teamManagerEmployee?.totalCount)) {
                allIds.push(data?.poc?.user?.id)
            }
            const uniqueIds = Array.from(new Set(allIds));
            return uniqueIds.length;
        }
        else {
            return 0
        }
    }
    const getOrgEmployeeLevelCount = async () => {
        setBannerCardsLoading(true)
        const { data } = await client.query({
            query: ORG_EMPLOYEE_LEVEL_COUNTS,
            fetchPolicy: 'network-only'
        });
        if (data?.me) {
            let org_detail = data?.me?.employee?.orgPocEmployee?.edges[0]?.node
            let counts = {
                total_verticals: org_detail ? org_detail?.verticalSet?.totalCount : 0,
                total_teams: org_detail ? org_detail?.verticalSet?.edges?.map(({ node }) => node?.teamSet?.totalCount).reduce((acc, curr) => acc + curr, 0) : 0,
                total_mangers: org_detail ? managerCount(org_detail) : 0,
                total_reporties: org_detail ? org_detail?.employees?.totalCount - managerCount(org_detail) : 0,
            }
            setOrgEmployeeLevelCount(counts)
            setBannerCardsLoading(false)
        }
    }

    return (
        <>
            {(permission && !permission?.required_permission) && (<NoPermissionView />)}

            {
                (permission && permission?.required_permission) && (
                    <PocDashboardHomeView {...props} org_survey_list={org_survey_list} view_dashboard_cards={view_dashboard_cards} banner_card_loading={banner_card_loading} org_employee_level_count={org_employee_level_count}  licenses={licenses} licenses_loading={licenses_loading} />
                )
            }
            {
                !permission && (
                    <div className="role-no-permission-container">
                        <Spin spinning={true} size="large"></Spin>
                    </div>
                )
            }
        </>
    )

}

export default compose(withApollo)(PocDasboardHomeContainer);