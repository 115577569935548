import React from "react";
import PocDasboardSurveyAssessmentView from "../../components/dashboard/PocDasboardSurveyAssessmentView";
import { ORG_POC_SURVEY_LIST,ORG_SURVEY_BY_ID } from "modules/poc-dashboard/graphql/pocDashboardQuery.gql";
import { compose } from "modules/core";
import { withApollo } from '@apollo/client/react/hoc';
import { ORG_POC_VERTICAL_GRAPH_DATA } from "modules/poc-dashboard/graphql/organizationQuery.gql";

const PocDashboardAssessmentContainer =(props)=>{
    const {client,me,match,org_survey_list}=props
    const [verticals_assessment_data,setVerticalAssessmentData]=React.useState()
    const [data_list_loading,setDataListLoading] = React.useState(false)
    React.useEffect(() => {
        if (me?.employee&&org_survey_list?.length>0) {
            verticalAssessmentData({id:me?.employee?.organizationSet?.edges[0]?.node?.id,survey_id:org_survey_list[0]?.id})
        }

    }, [me,org_survey_list])
    const parseObject = (str) => {
        return JSON.parse(str.replace(/'/g, '"'));
    };
    const verticalAssessmentData=async(filter)=>{
        setDataListLoading(true)
        const { data } = await client.query({
            query: ORG_POC_VERTICAL_GRAPH_DATA,
            variables: {...filter},
            fetchPolicy: 'network-only'
        });
        if (data?.organizationById) {
            setVerticalAssessmentData(data?.organizationById?.surveySet?.edges[0]?.node?.summaryListByVertical?.map(parseObject))
            setDataListLoading(false)
        }
    }

    return (
        <>
        <PocDasboardSurveyAssessmentView
        verticals_assessment_data={verticals_assessment_data}
        getVerticalAssessmentData={(filter)=>verticalAssessmentData(filter)}
        data_list_loading={data_list_loading}
         {...props} />
        </>
    )
}

export default compose(withApollo)(PocDashboardAssessmentContainer)