import React from "react";

import { graphql } from "@apollo/client/react/hoc";
import update from "immutability-helper";
import _ from "lodash";
import { Button, Input, Space, message } from "antd";
import {
  SearchOutlined,
  UndoOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
} from "@ant-design/icons";
// import showdown from 'showdown';
import gql from "graphql-tag";

import { EMAIL_PERCEPTION_REPORT_QUERY } from "../../survey/graphql/EmailPerceptionReport.gql";
import { EMAIL_PREDICTION_REPORT_QUERY } from "../../survey/graphql/EmailPredictionReport.gql";
import { EMAIL_PERCEPTION_REPORT_TO_ADMIN_QUERY } from "../../survey/graphql/EmailPerceptionReportToAdmin.gql";
import { EMAIL_PREDICTION_REPORT_TO_ADMIN_QUERY } from "../../survey/graphql/EmailPredictionReportToAdmin.gql";
import {
  CheckBoxField,
  SelectField,
  OrganizationAutoComplete,
  SubverticalAutoComplete,
  SurveyAutoComplete,
  UserAutoComplete,
} from "..";
import { getSyncItem } from "../../core";
import moment from 'moment-timezone';
import SurveyResponseAutoComplete from "./form-components/SurveyResponseAutoComplete";
import { question_id_for_3cs } from "config";

const { Search } = Input;
// const converter = new showdown.Converter();

export function isJsonString( str ) {
  try {
    JSON.parse( str );
  } catch ( e ) {
    return false;
  }
  return true;
}

export function getUniqueListBy( arr, key ) {
  return [ ...new Map( arr.map( ( item ) => [ item[ key ], item ] ) ).values() ];
}

export const readableTime = ( secs ) => {
  secs = Math.round( secs );
  var hours = Math.floor( secs / ( 60 * 60 ) );

  var divisor_for_minutes = secs % ( 60 * 60 );
  var minutes = Math.floor( divisor_for_minutes / 60 );

  var divisor_for_seconds = divisor_for_minutes % 60;
  var seconds = Math.ceil( divisor_for_seconds );

  var obj = {
    h: hours,
    m: minutes,
    s: seconds,
  };
  return hours === 0
    ? minutes === 0
      ? `${seconds} secs`
      : `${minutes} mins ${seconds} secs`
    : `${hours} hr ${minutes} mins ${seconds} secs`;
};

export const displayDataCheck = ( text, bool = false ) => {
  if ( bool ) {
    return text ? "True" : "False";
  }
  return text ? text : " ";
};

export function validateEmail( email ) {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test( email );
}

export const displayBoolean = ( bool ) => {
  console.log(bool)
  if ( bool ) {
    return (
      <CheckCircleFilled
        style={{ color: "#009fff", margin: "2%", fontSize: "large" }}
      />
    );
  } else
    return (
      <CloseCircleFilled
        style={{ color: "#ff4d4f", margin: "2%", fontSize: "large" }}
      />
    );
};

export function getIntFromString( base64String ) {
  return typeof base64String === "string"
    ? Number( window.atob( base64String ).split( ":" )[ 1 ] )
    : base64String;
}

export function getTypeFromBase64String( base64String ) {
  return typeof base64String === "string"
    ? window.atob( base64String ).split( ":" )[ 0 ]
    : base64String;
}

export function getBase64StringFromInt( string, int ) {
  return window.btoa( `${string}:${int}` );
}

export function getBase64String(string) {
  return window.btoa(`${string}`);
}

export function getCursorFromInt( int ) {
  return getBase64StringFromInt( "arrayconnection", int );
}

export function getOrganizationBase64StringFromInt( int ) {
  return getBase64StringFromInt( "OrganizationType", int );
}

export function getVerticalBase64StringFromInt( int ) {
  return getBase64StringFromInt( "VerticalType", int );
}

export function getTeamBase64StringFromInt( int ) {
  return getBase64StringFromInt( "TeamType", int );
}

export function getEmployeeBase64StringFromInt( int ) {
  return getBase64StringFromInt( "EmployeeType", int );
}

export function getSurveyBase64StringFromInt( int ) {
  return getBase64StringFromInt( "SurveyType", int );
}

export function getResponsesBase64StringFromInt( int ) {
  return getBase64StringFromInt( "ResponseType", int );
}

export function getContactBase64StringFromInt( int ) {
  return getBase64StringFromInt( "ContactType", int );
}

export function getUserBase64StringFromInt( int ) {
  return getBase64StringFromInt( "UserType", int );
}

export function getStoryBase64StringFromInt( int ) {
  return getBase64StringFromInt( "StoryType", int );
}

export function getSurveyTokenBase64StringFromInt( int ) {
  return getBase64StringFromInt( "DetailedTokenListTypeEdge", int );
}

export function getQuestionBase64StringFromInt( int ) {
  return getBase64StringFromInt( "QuestionType", int );
}

export function getFaqCategoriesBase64StringFromInt( int ) {
  return getBase64StringFromInt( "FAQCategoryType", int );
}
export function getFaqQuestionBase64StringFromInt( int ) {
  return getBase64StringFromInt( "FAQQuestionType", int );
}

export function getTagBase64StringFromInt( int ) {
  return getBase64StringFromInt( "TagType", int );
}
export function getReadinessBase64StringFromInt( int ) {
  return getBase64StringFromInt( "ReadinessType", int );
}

export function getToolTypeBase64StringFromInt(int) {
  return getBase64StringFromInt("ToolType", int);
}

export function getMeetingTypeBase64StringFromInt(int) {
  return getBase64StringFromInt("MeetingType", int);
}
export function getDiscardTypeBase64StringFromInt(int) {
  return getBase64StringFromInt("DiscardRequestType", int);
}

export function getConversationTypeBase64StringFromInt(int) {
  return getBase64StringFromInt("CoachingConversationType", int);
}

export function getCompetencyTypeBase64StringFromInt(int) {
  return getBase64StringFromInt("CompetencyType", int);
}

export function getJobFamilyTypeBase64StringFromInt(int) {
  return getBase64StringFromInt("JobFamilyType", int);
}

export function getRoleTypeBase64StringFromInt(int) {
  return getBase64StringFromInt("RoleType", int);
}

export function getEmpLevelBase64StringFromInt(int) {
  return getBase64StringFromInt("EmployeeLevelType", int);
}

export function getBehaviourBase64StringFromInt(int) {
  return getBase64StringFromInt("BehaviourallyAnchoredRatingType", int);
}

export function getQualifyingBase64StringFromInt(int) {
  return getBase64StringFromInt("QualifyingLevelType", int);
}

export function getOKRBase64StringFromInt(int) {
  return getBase64StringFromInt("okrType", int);
}
export function getOKRContributorsStringFromInt(int) {
  return getBase64StringFromInt("ContributorType", int);
}
export function getBadgeStringFromInt(int) {
  return getBase64StringFromInt("BadgeType", int);
}

export function getCompetencyCategoryStringFromInt(int) {
  return getBase64StringFromInt("CompetencyCategoryType", int);
}
export function getCommitmentStringFromInt(int) {
  return getBase64StringFromInt("CommitmentType", int);
}
export function getGroupStringFromInt(int) {
  return getBase64StringFromInt("GroupType", int);
}
export function getScapeStringFromInt(int) {
  return getBase64StringFromInt("ScapeType", int);
}

export function getFeedbackStringFromInt(int) {
  return getBase64StringFromInt("FeedbackType", int);
}
export function getIdpBase64StringFromInt( int ) {
  return getBase64StringFromInt( "IdpType", int );
}

export function withMarkdownFieldValue(field, values) {
  // return _.set(values, field, converter.makeMarkdown(_.get(values, field)?.editor?.getData() || '')); // convert to markdown
  return _.set( values, field, _.get( values, field )?.editor?.getData() || "" );
}

export function withHTMLFieldValue( value ) {
  // return converter.makeHtml(value);
  return value;
}

export const GetColumnSearchProps = ( dataIndex, searchFunc, type, parameter ) => {
 
  const searchInput = React.useRef( null );
  function filterDropdown( {
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  } ) {
    let field;
    switch ( type ) {
      case "user":
        field = (
          <Space size={0} className='emp-selector'>
            <UserAutoComplete
              margin={false}
              ref={searchInput}
              placeholder={`Search ${dataIndex}`}
             
              onChange={(userId) => {
                if( typeof(userId)=== "number"){
                  searchFunc( getUserBase64StringFromInt( userId ) )
                }
              }
              }
              size="small"
              enterButton
            />
            <Button
              type="primary"
              size="small"
              icon={<UndoOutlined />}
              onClick={() => {
                searchFunc( "" );
                clearFilters();
              }}
            />
          </Space>
        );
        break;
      case "survey":
        field = (
          <Space size={0} className='emp-selector'>
            <SurveyAutoComplete
              margin={false}
              ref={searchInput}
              placeholder={`Search ${dataIndex}`}
              onChange={(surveyId) => {
                if( typeof(surveyId)=== "number"){
                  searchFunc(getSurveyBase64StringFromInt(surveyId))
                }
              }
              }
              size="small"
              enterButton
            />
            <Button
              type="primary"
              size="small"
              icon={<UndoOutlined />}
              onClick={() => {
                searchFunc( "" );
                clearFilters();
              }}
            />
          </Space>
        );
        break;
      case "organization":
        field = (
          <Space size={0}>
            <OrganizationAutoComplete
              margin={false}
              ref={searchInput}
              placeholder={`Search ${dataIndex}`}
              onChange={( organizationId ) =>
                searchFunc( getOrganizationBase64StringFromInt( organizationId ) )
              }
              size="small"
              enterButton
            />
            <Button
              type="primary"
              size="small"
              icon={<UndoOutlined />}
              onClick={() => {
                searchFunc( "" );
                clearFilters();
              }}
            />
          </Space>
        );
        break;
      case "subvertical":
        field = (
          <Space size={0}>
            <SubverticalAutoComplete
              margin={false}
              ref={searchInput}
              placeholder={`Search ${dataIndex}`}
              onChange={( organizationId ) =>
                searchFunc( getOrganizationBase64StringFromInt( organizationId ) )
              }
              size="small"
              enterButton
            />
            <Button
              type="primary"
              size="small"
              icon={<UndoOutlined />}
              onClick={() => {
                searchFunc( "" );
                clearFilters();
              }}
            />
          </Space>
        );
        break;
      case "binary":
        field = (
          <CheckBoxField
            label={_.upperFirst( _.camelCase( dataIndex ) )}
            defaultChecked={true}
            margin={false}
            onChange={( e ) => searchFunc( e.target.checked )}
          />
        );
        break;
      case "ternary":
        field = (
          <SelectField
            // label={_.upperFirst(_.camelCase(dataIndex))}
            style={{ width: 100, marginBottom: 0 }}
            margin={false}
            onChange={searchFunc}
            size="small"
            choices={[
              { value: "", label: "All" },
              { value: true, label: "Yes" },
              { value: false, label: "No" },
            ]}
          />
        );
        break;
        
      default:
        field = (

          <Space size={0}>
            <Search
              ref={searchInput}
              placeholder={`Search ${dataIndex}`}
              onSearch={( searchText ) => {
                searchFunc( searchText );
                confirm( { closeDropdown: true } );
              }}
              size="small"
              // loading={loading}
              enterButton
            />
            <Button
              type="primary"
              size="small"
              icon={<UndoOutlined />}
              onClick={() => {
                searchFunc( "" );
                clearFilters();
              }}
            />
          </Space>
        );
    }
    return <div style={{ padding: 8 }}>{field}</div>;
  }

  return (
    searchFunc && {
      filterDropdown,
      filterIcon: ( filtered ) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilterDropdownVisibleChange: ( visible ) => {
        if ( visible && !type ) {
          setTimeout( () => searchInput.current.select(), 100 );
        }
      },
    }
  );
};

export function getOrderBy( name, orderBy ) {
  console.log("name, orderBy",name, orderBy)
  return orderBy?.indexOf( name ) !== -1 ? [ `-${name}` ] : [ name ];
}

export const generateReport = async ( id, surveId, client ) => {
  try {
    if ( Number( surveId ) === 1 ) {
      client.query( {
        query: EMAIL_PERCEPTION_REPORT_QUERY,
        variables: { responseId: id },
      } );
    } else if ( Number( surveId ) === 2 ) {
      client.query( {
        query: EMAIL_PREDICTION_REPORT_QUERY,
        variables: { responseId: id },
      } );
    }
  } catch ( e ) {
    console.error( e.message );
    // throw new Error(e.message);
  }
};

export const generateReportDataForRequestedUsersSurvey = async (
  mutation,
  variables,
  client
) => {
  try {
    if ( mutation ) {
      const { data } = await client.query( {
        query: gql`
        query ${mutation}($requestId: ID, $requestedUserId: ID) {
            ${mutation}(requestId: $requestId, requestedUserId: $requestedUserId)
          }
        `,
        variables,
      } );
      return data[ mutation ] && JSON.parse( data[ mutation ] );
    }
  } catch ( e ) {
    console.error( e.message );
    // throw new Error(e.message);
  }
};

export const generateReportDataForRequestedUsersIdSurvey = async (
  isVariables,
  mutation,
  variables,
  client
) => {
  try {
    if ( mutation ) {
      if ( isVariables ) {
        const { data } = await client.query( {
          query: gql`
           query ${mutation}($requestId: ID, $requestedUserId: ID) {
            ${mutation}(requestId: $requestId, requestedUserId: $requestedUserId)
            }
          `,
          variables,
        } );
        return data[ mutation ] && JSON.parse( data[ mutation ] );
      } else {
        const { data } = await client.query( {
          query: gql`
          query ${mutation}($responseId: ID!) {
              ${mutation}(responseId: $responseId)
            }
          `,
          variables,
        } );
        return data[ mutation ] && JSON.parse( data[ mutation ] );
      }
    }
  } catch ( e ) {
    console.error( e.message );
    // throw new Error(e.message);
  }
};

export async function handleGetReport( surveyId, client, variables, responseId ) {
  let mutation;
  let usermutaion;
  switch ( surveyId ) {
    case 1: //'Workplace MApp Perception Survey'
      mutation = "getReportDataForRequestedUsersPerceptionSurvey";
      usermutaion = "getPerceptionReportDataForUserByResponseId";
      break;
    case 2: //'Workplace MApp Prediction Survey'
      mutation = "getReportDataForRequestedUsersPredictionSurvey";
      usermutaion = "getPredictionReportDataForUserByResponseId";
      break;
    default:
      usermutaion = false;
  }

  const report = await generateReportDataForRequestedUsersIdSurvey(
    variables,
    variables ? mutation : usermutaion,
    variables ? variables : { responseId },
    client
  );
  if ( report ) {
    return { report, success: true };
  } else {
    return {
      errors: "Sorry! Comparison report for this survey does not exist.",
      sucess: false,
    };
  }
}

export const generateAdminReport = async ( id, surveId, userId, client ) => {
  try {
    if ( Number( surveId ) === 1 ) {
      client.query( {
        query: EMAIL_PERCEPTION_REPORT_TO_ADMIN_QUERY,
        variables: { responseId: id, userId },
      } );
    } else if ( Number( surveId ) === 2 ) {
      client.query( {
        query: EMAIL_PREDICTION_REPORT_TO_ADMIN_QUERY,
        variables: { responseId: id, userId },
      } );
    }
  } catch ( e ) {
    console.error( e.message );
    // throw new Error(e.message);
  }
};

export const operationUpdate = ( updateArgs, addInfo ) => {
  const {
    cache,
    mutationResult: { data },
  } = updateArgs;
  const {
    query,
    ownProps: { pagination, filter, orderBy, navigation, match, ...rest },
    getFunc,
    node,
    variable,
  } = addInfo;
  let id = "";
  if ( match ) {
    id = match.params.id;
  } else if ( navigation ) {
    id = navigation.state.params.id;
  }
  const variables = variable
    ? variable.type === "all"
      ? variable.custom
        ? _.set(
          _.omit(
            {
              ...pagination,
              ...filter,
              orderBy,
            },
            variable.omit
          ),
          variable.varName,
          Number( id ) || _.get( rest, variable.varName )
        )
        : _.set(
          {
            ...pagination,
            ...filter,
            orderBy,
          },
          variable.varName,
          Number( id ) || _.get( rest, variable.varName )
        )
      : _.set( {}, variable.varName, Number( id ) || _.get( rest, variable.varName ) )
    : orderBy
      ? {
        ...pagination,
        ...filter,
        orderBy,
      }
      : {
        ...pagination,
        ...filter,
      };
  // Get previous data from cache
  const queryArgs = {
    query,
    variables,
  };
  // console.log('queryArgs', queryArgs);
  const prev = cache.readQuery( queryArgs );
  // console.log('queryArgs-prev', prev);
  // console.log('prev', prev);
  const updatedData = getFunc( prev, _.get( data, node ) );
  // console.log('updatedData', updatedData);
  // Write data to cache
  cache.writeQuery( {
    ...queryArgs,
    data: {
      ...updatedData,
    },
  } );
};

// Mutation
export const withOperation = ( {
  funcName,
  mutation,
  mutationVarName,
  query,
  queryName,
  node,
  type,
  variable,
  dataEdge = true,
} ) =>
  graphql( mutation, {
    props: ( { mutate, ownProps } ) =>
      _.set( {}, funcName, async ( values ) => {
        const variables = mutationVarName
          ? _.set( {}, mutationVarName, values )
          : values;
        // console.log(variables);
        try {
          const { data } = await mutate( {
            variables: {
              ...variables,
            },

            update: ( cache, mutationResult ) =>
              operationUpdate(
                { cache, mutationResult },
                {
                  query,
                  ownProps,
                  getFunc: ( prev, node ) => {
                    switch ( type ) {
                      case "add":
                        return onAdd( prev, node, queryName );
                      case "edit":
                        return onEdit( prev, node, queryName, dataEdge );
                      case "delete":
                        return onDelete( prev, node.id, queryName, dataEdge );
                      default:
                        console.log( "Mutation type un-recognised!" );
                    }
                  },
                  node,
                  variable,
                }
              ),
          } );
          message.destroy();
          message.success( "Success!" );
          return _.get( data, node );
        } catch ( e ) {
          message.destroy();
          // message.error( "Failed!" );
          console.error( e );
          throw new Error( e );
        }
      } ),
  } );

// Subscription
export const subscribeToQuery = (
  subscribeToMore,
  {
    document,
    subscriptionName,
    nodeName,
    queryName,
    dataEdge = true,
    shouldSubscrbe,
  },
  variables
) =>

  subscribeToMore( {
    document,
    variables,

    updateQuery: ( prev, { subscriptionData: { data } } ) => {
      let newResult = prev;
      const subscriptionData = _.get( data, subscriptionName );
      const mutation = _.get( subscriptionData, "mutation" );
      const node = _.get( subscriptionData, nodeName );
      console.log( "prevvv", mutation, node,queryName )
      let path = queryName;
      const edgesIndex = queryName.indexOf( "edges" );
      if ( edgesIndex !== -1 ) {
        const subEdgeIndex = _.get( prev, queryName.slice( 0, edgesIndex + 1 ) )?.map(
            ( { node: subEdge } ) =>
              _.get( subEdge, [
                ...queryName?.slice( edgesIndex + 1 ),
                "edges",
              ] )?.filter( ( { node: subSubEdge } ) => subSubEdge.id === node.id )
                .length > 0
          )?.indexOf( true );
        if(path){
        path.splice( edgesIndex + 1, 0, String( subEdgeIndex ) );
        path.splice( edgesIndex + 2, 0, String( "node" ) );
        }
      }

      
      // console.log( 'bleh', mutation, node );
      if ( shouldSubscrbe ) {
        if ( shouldSubscrbe( node ) ) {
          if ( mutation === "CREATE" ) {
            newResult = onAdd( prev, node, path );
          } else if ( mutation === "UPDATE" ) {
            newResult = onEdit( prev, node, path, dataEdge );
          } else if ( mutation === "DELETE" ) {
            newResult = onDelete( prev, node.id, path, dataEdge );
          }
          return newResult;
        }
      } else {
        if ( mutation === "CREATE" ) {
          
          // newResult = onAdd( prev, node, path );
        } 
        else if ( mutation === "UPDATE" ) {
          // newResult = onEdit( prev, node, path, dataEdge );
        } else if ( mutation === "DELETE" ) {
          // newResult = onDelete( prev, node.id, path, dataEdge );
        }
        return newResult;
      }
    },
  } );

export function onAdd( prev, node, dataIndex ) {
  console.log( 'onADD', prev, node, dataIndex );
  const data = _.get( prev, dataIndex );
  // console.log(prev, dataIndex, data);
  if ( data?.edges?.some( ( dataEdge ) => node.id === dataEdge.cursor ) ) {
    const updatedData = _.set( {}, dataIndex, {
      edgeCount: {
        $set: data.edges.length,
      },
      edges: {
        $set: data.edges,
      },
    } );
    return update( prev, updatedData );
  }

  const filteredData = data?.edges?.filter(
    ( dataEdge ) => dataEdge.node.id !== node.id
  );

  const dataEdge = {
    cursor: getCursorFromInt( getIntFromString( node.id ) ),
    node,
    __typename: ( data?.edges[ 0 ] && data?.edges[ 0 ]?.__typename ) || "",
  };
  let edge_values 
  if(filteredData?.length){
    edge_values =[ dataEdge, ...filteredData ]
  }
  else{
    edge_values =[ dataEdge]
  }
  data?.pageInfo?.hasNextPage && filteredData.pop(); // to remove last element from array.
  const updatedData = _.set( {}, dataIndex, {
    edgeCount: {
      $set: edge_values?.length,
    },
    edges: {
      $set: edge_values,
    },
  } );
  return update( prev, updatedData );
}

export function onEdit( prev, node, dataIndex, edge ) {
  // console.log( 'prev', prev, node, dataIndex, edge );
  const data = _.get( prev, dataIndex );
  const index = data?.edges?.findIndex(
    ( dataEdge ) => dataEdge.node.id === node.id
  );
  const dataEdge = {
    cursor: node.id,
    node,
  };
  if ( index !== -1 ) {
    data.edges && data.edges.splice( index, 1, dataEdge );
    const updatedData = _.set(
      {},
      dataIndex,
      edge
        ? {
          edges: {
            $set: [ ...data.edges ],
          },
        }
        : {
          $set: node,
        }
    );
    return update( prev, updatedData );
  } else onAdd( prev, node, dataIndex, edge );
}

export const onDelete = ( prev, id, dataIndex, edge ) => {
  if ( !edge && typeof window !== undefined ) window.location = "/";

  // console.log('called', prev, id);
  const data = _.get( prev, dataIndex );
  // console.log(dataIndex, data, id);
  const index = data?.edges?.findIndex(
    ( dataEdge ) => getIntFromString( dataEdge.node.id ) === getIntFromString( id )
  );
  // console.log('index', index);

  // ignore if not found
  if ( index < 0 ) {
    return prev;
  }
  const updatedData = _.set( {}, dataIndex, {
    totalCount: {
      $set: data.totalCount - 1,
    },
    edgeCount: {
      $set: data.edgeCount - 1,
    },
    edges: {
      $splice: [ [ index, 1 ] ],
    },
  } );
  // console.log(dataIndex);
  return update( prev, updatedData );
};

export const removeDuplicate = ( data ) => {
  let dataList = {
    edgeCount: 0,
    edges: [],
  };
  data.map(
    ( d ) =>
      dataList.edges.indexOf( d.node.id ) === -1 && dataList.edges.push( d.node.id )
  );
  dataList.edges = dataList.edges.map(
    ( dL ) => data.filter( ( d ) => d.node.id === dL )[ 0 ]
  );
  dataList.edgeCount = dataList.edges.length;
  return dataList;
};

export const getOrgListFromVer = ( ver ) => {
  let orgList = {
    edgeCount: 0,
    edges: [],
  };
  ver.edges.map( ( { node } ) => orgList.edges.push( { node: node.organization } ) );
  return removeDuplicate( orgList.edges );
};

export const getOrgListFromTeam = ( team ) => {
  let orgList = {
    edgeCount: 0,
    edges: [],
  };
  team.edges.map( ( { node } ) =>
    orgList.edges.push( { node: node.vertical.organization } )
  );
  return removeDuplicate( orgList.edges );
};

export function getMarks( marks ) {
  const obj = {};
  obj[ marks[ 0 ] ] = marks[ 0 ];
  obj[ marks[ 1 ] ] = marks[ 1 ];
  return obj;
}
export function getStrokeColor( value ) {
  if ( value > 9 ) {
    return "#009C4A";
  } else if ( value > 7.5 ) {
    return "#B0CB1F";
  } else if ( value > 5 ) {
    return "#CBA51F";
  } else {
    return "#E83D2A";
  }
}

export function calculateDaysBetweenDates( startDate, endDate ) {
  const start = moment( startDate );
  const end = moment( endDate );
  const duration = moment.duration( end.diff( start ) );
  const days = duration.asDays();
  return days;
}

export function displayUtcToUserTimezone(date, dateFormat){
  return moment.utc(date).tz(getSyncItem("userRequestedTimezone") || Intl.DateTimeFormat().resolvedOptions().timeZone).format(dateFormat);
}

export function getMomentUtcToUserTimezone(date) {
  return moment.utc(date).tz(getSyncItem("userRequestedTimezone") || Intl.DateTimeFormat().resolvedOptions().timeZone);
}

export function getMomentUtc(date) {
  return moment.utc(date);
}

export function getMomentUserTimezone(date) {
  return moment(date).tz(getSyncItem("userRequestedTimezone") || Intl.DateTimeFormat().resolvedOptions().timeZone);
}

export function getMomentUserTimezoneWithFormat(date, dateFormat) {
  return moment(date).tz(getSyncItem("userRequestedTimezone") || Intl.DateTimeFormat().resolvedOptions().timeZone).format(dateFormat);
}


export function getMoment(date) {
  return moment(date);
}

export function interpolate( t, c ) {
  return t.replace(
    /\${([^}]+)}/g,
    ( m, p ) => p.split( "." ).reduce( ( a, f ) => ( a ? a[ f ] : undefined ), c ) ?? m
  );
}

export const scrolltoview = ( id ) => {
  let node = document.getElementById( id + '_scroll' )
  if ( node ) {
    node.scrollIntoView( { block: 'center', behavior: 'smooth' } );
    node.className += "backgroundAnimated";
    setTimeout( () => {
      if ( node.className.includes( 'backgroundAnimated' ) ) {
        node.className = node.className.replace( "backgroundAnimated", ' ' )
      }
    }, 6000 );
  }
}

export const customScrollToView = ( id,position,timeOut,hightLight=false ) => {
  setTimeout(()=>{
    let node = document.getElementById( id + '_scroll' )
    if ( node ) {
      node.scrollIntoView( { block: position, behavior: 'smooth' } );
      if(hightLight){
        node.className += "backgroundAnimated";
        setTimeout( () => {
          if ( node.className.includes( 'backgroundAnimated' ) ) {
            node.className = node.className.replace( "backgroundAnimated", ' ' )
          }
        }, 6000 );
      }
    }
  },timeOut)
}

export const customTabSelection=(id)=>{
  let tab = document.getElementsByClassName('ant-tabs-nav-list')
  if(tab){
    let tabChild= tab[0].childNodes[tab[0].childNodes.length-1]
    if(tabChild){
      tabChild.className =`ant-tabs-ink-bar ant-tabs-ink-bar-animated ${id}-tab-color`
    }
    // tab.className =`ant-tabs-ink-bar ant-tabs-ink-bar-animated ${id}-tab-color`
  }
}
// export const getTime = ( date ) => {
//   let event = new Date( date )
//   return event.toLocaleTimeString()
// } 

export const parseMarkDownText =(text)=>{
  let data =  text?.replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;|&#39;/g,'')
  return data || ' '
}

export const startOfQuarter = moment().quarter(moment().quarter()).startOf('quarter');

export const endOfQuarter = moment().quarter(moment().quarter()).endOf('quarter');

export const localTz = moment.tz.guess();

export const permissionValidation=(required_permission=[],userPermission)=>{
  let current_permission=false
  required_permission.forEach(element => {
    if(userPermission?.includes(element)){
      current_permission=true
    }else{
     return  current_permission = false
    }
  });
  return current_permission
}

export const globalPermissionValidator=(permission,userPermission)=>{
  let current_permission={}
  Object.entries(permission).forEach(item=>{
    current_permission[item[0]]=item[1].every(string => userPermission.includes(string));
  })
  return current_permission
}

export const customTabSelectionForDate=()=>{
  let tab = document.getElementsByClassName('ant-picker-input')
  if(tab){
    let tabChild= tab[0]?.childNodes[tab[0]?.childNodes?.length-1]
    let tabChild_2= tab[1]?.childNodes[tab[1]?.childNodes?.length-1]
    console.log("tab",tab)
    console.log("tabChild",tabChild)
    if(tabChild){
      tabChild.id ='custom_start_date'
      // tabChild.placeholder.replace('Start date','-')
      tabChild.setAttribute('placeholder','-')
    }
    if(tabChild_2){
      tabChild_2.id ='custom_end_date'
      tabChild_2.placeholder.replace('End date','-')
    }
    // tab.className =`ant-tabs-ink-bar ant-tabs-ink-bar-animated ${id}-tab-color`
  }
}



export const placeholderTextForCkeditor = (data) => {
        
  switch (getIntFromString(data?.id)) {
      case 683:
          return "Create Safety by stating how the feedback will help the receiver..."
      case 684:
          return "Lay out context by referring to a specific situation, so the feedback is not generalized..."
      case 685:
          return "Articulate behavioral evidence by describing actions / speech objectively and without personal opinions..."
      case 686:
          return "Probe for possible alternatives that the receiver can try to act on the feedback..."
      case 687:
          return "Support for next steps and commitments by offering help, advice and by being an accountability partner..."

     }
  }


  export const getSelectedFeedbackType = (enumValue,feedbackType) =>{

    if(feedbackType){
       let type = enumValue[feedbackType]

       switch (type){
        case 'MOTIVATIONAL_RECOGNITION':
          return 'Motivational/Recognition';        
        case 'DEVELOPMENTAL_CONSTRUCTIVE':
          return 'Developmental/Constructive'
        default:
          break;
       }
    }

  }

  export const ToolTipTitle = () => {
    return (
      <div>
        <div>C -Create Safety by stating how the feedback will help the receiver.</div>
        <div>L - Lay out context by referring to a specific situation, so the feedback is not generalized.</div>
        <div>A - Articulate behavioral evidence by describing actions / speech objectively and without personal opinions.</div>
        <div>P - Probe for possible alternatives that the receiver can try to act on the feedback.</div>
        <div>S - Support for next steps and commitments by offering help, advice and by being an accountability partner.</div>
      </div>
    )

  }

  export const changeToCurrentTime = (date)=>{
    let current_time = moment()
    let hour = current_time.get('hour')
    let minute = current_time.get('minute')
    let second = current_time.get('second')
    let millisec = current_time.get('millisecond')
    return moment(date).set('hour',hour).set('minute',minute).set('second',second).set('millisecond',millisec).utc()
  }

  export function getAssessmentBase64StringFromInt( int ) {
    return getBase64StringFromInt( "AssessmentType", int );
  }

  export function generteRandomColor(){
    var letters = '0123456789abcdef';
    var color = '#';
    var arr = letters.split('');
    for (let j = 1; j <= 6; j++) {
      var index = Math.floor(Math.random() * 16);
      color += arr[index];
    }
    return color;
  }


  export const CUSTOM_EMAIL_ROUTE = JSON.parse(localStorage.getItem('emailRoute'))

  export const objectSanitizer = (obj) => {
    Object.keys(obj).forEach((key) => ([undefined, '', null].includes(obj[key]) || !obj[key]?.length) && delete obj[key])
    return obj
}

export const conditionCheck = (lhs,rhs,conditon)=>{
  let condition_satisfied = false
  switch (conditon) {
    case "<":
      condition_satisfied = lhs < rhs
      break;
    case "<=":
      condition_satisfied = lhs <= rhs
      break;
    case ">":
      condition_satisfied = lhs > rhs
      break;
    case ">=":
      condition_satisfied = lhs >= rhs
      break;
    case "==":
      condition_satisfied = lhs == rhs
      break;
    default:
      break;
  }
  return condition_satisfied
}

export const FEEDBACK_FETCH_QTY = 9

const replacer = (key, value) => {
  return value === null ? '-' : value;
};

const convertToCsv = (data) => {
  const headers = Object.keys(data[0]);
  const csvRows = [headers.join(','), ...data.map(row => headers.map(field => JSON.stringify(row[field], replacer)).join(','))];

  return csvRows.join('\n');
};
export  const exportToCsv = (filename, data) => {
  console.log("filename, data",filename, data)
  const csvContent = convertToCsv(data);
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', `${filename}.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
 
};

// donot change this function
export function interpolateFor3cs( t, c ) {
 
  if(c[question_id_for_3cs] && t?.includes(question_id_for_3cs)){

    let name = c[question_id_for_3cs].replace(/\s*<email style="display:none">.*?<\/email>\s*/g, '')
    if(name){
      return t.replace("${692}",name)
    }else{
      return t.replace(
        /\${([^}]+)}/g,
        ( m, p ) => p.split( "." ).reduce( ( a, f ) => ( a ? a[ f ] : undefined ), c ) ?? m
      );
    }
  }else{
    return t.replace(
      /\${([^}]+)}/g,
      ( m, p ) => p.split( "." ).reduce( ( a, f ) => ( a ? a[ f ] : undefined ), c ) ?? m
    );
  }
 
}

export const color_list_for_assessment = [
  "rgba(38, 196, 165, 1)",
  "rgba(84, 217, 159, 1)",
  "rgba(172, 224, 140, 1)",
  "rgba(232, 226, 132, 1)",
  "rgba(242, 182, 82, 1)",
  "rgba(78, 209, 218, 1)",
  "rgba(132, 232, 142, 1)"
]
