import ROUTE from './routes/index';
import CreateInvitationContainer from './survey/container/CreateInvitationContainer';
import OrgSurveyContainer from './survey/container/OrgSurveyContainer';
import Survey360Container from './survey/container/Survey360Container';
import Survey360reportContainer from './report/container/Survey360reportContainer';
import Survey360ManagerReportContainer from './report/container/Survey360ManagerReportContainer';
import GenericTool from 'modules/tool/containers/GenericTool';
import ReportContainer from './report/container/ReportContainer';
export default [
  {
    name: 'all survey',
    path: ROUTE.org_survey,
    component: OrgSurveyContainer,
    exact: true,
    protect: true
  },
  {
    name: '360Survey',
    path: ROUTE.survey_360,
    component: Survey360Container,
    exact: true,
    protect: true
  },
  {
    name: '360SurveyInvite',
    path: ROUTE.survey_360_add,
    component: CreateInvitationContainer,
    exact: true,
    protect: true
  },
  {
    name: 'Report',
    path: ROUTE.report,
    component: ReportContainer,
    exact: true,
    protect: true
  } ,
  {
    name: '360Surveyreport',
    path: ROUTE.survey_360_report,
    component: Survey360reportContainer,
    exact: true,
    protect: true
  },
  {
    name: '360SurveyManagerreport',
    path: ROUTE.survey_360_manager_report,
    component: Survey360ManagerReportContainer,
    exact: true,
    protect: true
  },
  {
    name: 'Report Tool',
    path: ROUTE.report_tool,
    component: GenericTool,
    exact: true,
    protect: true
  }
]