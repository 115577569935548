import React from 'react';
import PocDashboartBarChart from './DashboardSurveyBarChart';
import { Select } from 'antd';
import styled from 'styled-components';
import { color_list_for_assessment } from 'modules/look';

const PocDasboardSurveyAssessmentView = (props) => {
    const { org_survey_list,verticals_assessment_data,getVerticalAssessmentData,me,data_list_loading } = props
    const [filter, setFilter] = React.useState()

    const [graph_data_list, setGraphDataList] = React.useState([])
  
    const generateRandomRGBA = () => {
        const red = Math.floor(Math.random() * 256);
        const green = Math.floor(Math.random() * 256);
        const blue = Math.floor(Math.random() * 256);
        const alpha = Math.random() * (1 - 0.5) + 0.5;
        return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
    }
    
    React.useEffect(()=>{
        if(verticals_assessment_data?.length > 0){
           
            let graph_data = verticals_assessment_data?.map((item,index)=>{
                let random_color = generateRandomRGBA()
                return {
                    title: item?.Vertical_Name,
                    value: item["Completed%"],
                    color:  color_list_for_assessment[index]||random_color,
                    detail: "",
                    id: item?.Vertical_Id
                }
            })
            setGraphDataList(graph_data)
        }
    },[verticals_assessment_data])
    React.useEffect(() => {
        if (org_survey_list?.length > 0) {
            setFilter({ id: org_survey_list[0]?.id, name: org_survey_list[0]?.name })
        }
    }, [org_survey_list])
    return (
        <>
            {org_survey_list?.length > 0 && <div className="dasboard-banner-card" style={{ width: "100%" }}>
                <div style={{ display: "flex", justifyContent: 'space-between', gap: "1em", alignItems: 'center', width: "100%", flexWrap: "wrap" }}>
                    <h4 className="poc-dashboard-card-title">Survey Assessment status</h4>
                    <CustomSelect dropdownClassName="content-dropdown" onChange={(e, item) =>{ setFilter({ id: item?.key, name: item?.value,is_360:item?.label });getVerticalAssessmentData({id:me?.employee?.organizationSet?.edges[0]?.node?.id,survey_id:item?.key})}} defaultValue={filter ? filter?.name : org_survey_list[0]?.name}>
                        {org_survey_list?.map(item => (
                            <Select.Option className='dashobard-performance-table-heading' style={{ color: '#A3A3A3', fontFamily: "Poppins", fontWeight: 500 }} key={item?.id} value={item?.name} label={item?.multisourceResponse}>{item?.name}</Select.Option>
                        ))}
                    </CustomSelect>
                </div>
                <PocDashboartBarChart table_loading={data_list_loading} filter={filter} graph_data_list={graph_data_list} noDataMessage={'No Vertical Assessment Found'} requiredNavigation={true} {...props} />
            </div>}
        </>
    )
}

export default PocDasboardSurveyAssessmentView;

const CustomSelect = styled(Select)`
.ant-select-selector{
    border: 1px solid #AABAC6 !impoortant;
    color:  #000;
    font-weight: 500;
    font-family: Poppins;
    font-size: .85em;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.01em;
    text-align: left;
    border-radius:.75em !important;
    width:250px !important;
}
`;